<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="mb-3" order="1" cols="12" md="6" lg="4" xl="3">
        <div class="h-100 d-flex flex-column">
          <b-row class="same-rent mb-3">
            <b-col>
              <dashboard-big-number-a
                  title="Total de inscritos"
                  popover="Total de inscritos"
                  class="h-100"
              >{{ totalInscritos }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
          <b-row class="same-rent mb-3">
            <b-col>
              <dashboard-big-number-a
                  title="Acessos únicos"
                  popover="Acessos únicos momento"
                  class="h-100"
              >{{ acessosUnicos }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
          <b-row class="same-rent">
            <b-col>
              <dashboard-big-number-a
                  title="Pico de usuários simultâneos"
                  popover="Pico de usuários simultâneos"
                  class="h-100"
              >{{ totalPico }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="mb-3" order="2" order-xl="1" cols="12" xl="6">
        <dashboard-line-chart
            title="Evolução de inscritos"
            popover="Evolução de inscritos"
            series-title="online"
            :series-data="evolucaoInscritosGraph.dataList"
            :series-label="evolucaoInscritosGraph.labelList"
            class="h-100"
        />
      </b-col>
      <b-col class="mb-3" order="1" cols="12" md="6" lg="8" xl="3">
        <dashboard-radial-chart
            title="Taxa de participação"
            popover="Taxa de participação no momento"
            :series-value="taxaUsuariosOnline"
            series-name="Participação"
            class="h-100"
        />
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de perguntas recebidas"
            popover="Total de perguntas recebidas"
        >{{ totalPerguntas }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de quiz respondidos"
            popover="Total de quiz respondidos"
        >{{ totalRespostas }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de enquetes respondidas"
            popover="Total de enquetes respondidas"
        >{{ totalEnquetes }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de palavras núvem"
            popover="Total de palavras núvem"
        >{{ totalPalavrasNuvem }}
        </dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" xl="7">
        <dashboard-big-number-a
            title="Total de reações"
            popover="Total de reações"
        >
          <div class="d-flex justify-content-between">
            <div v-for="(item, index) in totalReacoesList" :key="'reacao-'+index">
              {{ item.reacao }} {{ item.total }}
            </div>
          </div>
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" xl="5">
        <dashboard-big-number-b
            class="h-100"
            :value-list="detailList3"
        />
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col  class="mb-3" cols="12" md="9">
        <dashboard-area-chart
            title="Registro de usuários online"
            popover="Registro de usuários online"
            series-title="online"
            :series-data="chartDataUsuarioOnlineData"
            :series-label="chartDataUsuarioOnlineLabel"
        />
      </b-col>
      <b-col  class="mb-3" cols="12" md="3">
        <dashboard-detail-list
            class="h-100"
            :list="detailList"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3" cols="12" lg="9">
        <dashboard-table-data
            title="Ranking Gamificação"
            popover="Ranking Gamificação"
            :items="rankingGamificacaoList"
        />
      </b-col>
      <b-col class="mb-3" cols="12" lg="3">
        <dashboard-detail-list
            :list="detailList2"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardTableData from '@/components/dashboards/dashboard-table-data'
import DashboardDetailList from '@/components/dashboards/dashboard-detail-list'
import DashboardBigNumberB from '@/components/dashboards/dashboard-big-number-b'
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import DashboardRadialChart from '@/components/dashboards/dashboard-radial-chart'
import DashboardAreaChart from '@/components/dashboards/dashboard-area-chart'
import DashboardLineChart from '@/components/dashboards/dashboard-line-chart'
import { subscribers } from '@/modules/dashboards/http/subscribers'
import { subscribersGraph } from '@/modules/dashboards/http/subscribersGraph'
import { usersOnline } from '@/modules/dashboards/http/usersOnline'
import { usersOnlineRate } from '@/modules/dashboards/http/usersOnlineRate'
import { usersOnlineGraph } from '@/modules/dashboards/http/usersOnlineGraph'
import { totalMetricsEvent } from '@/modules/dashboards/http/totalMetricsEvent'
import { reactEmojis } from '@/modules/dashboards/http/reactEmojis'
import { ranking } from '@/modules/dashboards/http/ranking'
import defaultAvatar from '@/assets/images/users/default.webp'
import { pageviews } from '@/modules/dashboards/http/pageviews'

export default {
  name: 'Analytics',
  components: {
    DashboardLineChart,
    DashboardTableData,
    DashboardDetailList,
    DashboardBigNumberB,
    DashboardBigNumberA,
    DashboardRadialChart,
    DashboardAreaChart
  },
  data: () => ({
    totalInscritos: 0,
    acessosUnicos: 0,
    totalPico: 0,
    taxaUsuariosOnline: 0,
    evolucaoInscritosGraph: {
      dataList: [],
      labelList: []
    },
    detailList: [
      {
        label: 'Posts newsfeed',
        value: 'xxxx',
        info: 'Popover!'
      },
      {
        label: 'Total de curtidas',
        value: 'xxxx',
        info: 'Popover!'
      },
      {
        label: 'Total de comentários',
        value: 'xxxx',
        info: 'Popover!'
      }
    ],
    detailList2: [
      {
        label: 'Pesquisa de satisfação respondidas',
        value: 'xxxx',
        // valueEmphasis: '25%',
        info: 'Popover!'
      },
      {
        label: 'Certificados gerados',
        value: 'xxxx',
        info: 'Popover!'
      }
    ],
    detailList3: [
      {
        title: 'Total de mensagens',
        value: 'Dado indisponível',
        info: 'Total de mensagens recebidas pelo sistema'
      },
      {
        title: 'Tempo médio de conexão',
        value: 'Dado indisponível',
        info: 'Tempo médio de conexão com o sistema'
      }
    ],
    totalPerguntas: 0,
    totalRespostas: 0,
    totalEnquetes: 0,
    totalPalavrasNuvem: 0,
    totalReacoesList: [],
    chartDataUsuarioOnlineData: [],
    chartDataUsuarioOnlineLabel: [],
    rankingGamificacaoList: []
  }),
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    async updateValues () {
      if (this.onUpdate) {
        return
      }
      this.onUpdate = true
      await Promise.allSettled([
        this.updateSubscribers(),
        this.updateSubscribersGraph(),
        this.updateUsersOnline(),
        this.updateUsersOnlineRate(),
        this.updateUsersOnlineGraph(),
        this.updateTotalMetricsEvent(),
        this.updateReaction(),
        this.updateRanking(),
        this.updatePageviews()
      ])
      this.onUpdate = false
    },
    async updateSubscribers () {
      const subscribersTotal = await subscribers(this.filterDates)
      this.totalInscritos = subscribersTotal.total
    },
    async updateSubscribersGraph () {
      const data = await subscribersGraph(this.filterDates)
      this.evolucaoInscritosGraph.dataList = data.list
      this.evolucaoInscritosGraph.labelList = data.label
    },
    async updateUsersOnline () {
      const data = await usersOnline(this.filterDates)
      this.totalPico = data.peak
    },
    async updateUsersOnlineRate () {
      const data = await usersOnlineRate(this.filterDates)
      this.taxaUsuariosOnline = data.rate
    },
    async updateUsersOnlineGraph () {
      const data = await usersOnlineGraph(this.filterDates)
      this.chartDataUsuarioOnlineData = data.list
      this.chartDataUsuarioOnlineLabel = data.label
    },
    async updateTotalMetricsEvent () {
      const data = await totalMetricsEvent(this.filterDates)
      this.totalPalavrasNuvem = data.wordCloud
      this.totalPerguntas = data.asking
      this.totalRespostas = data.quiz
      this.totalEnquetes = data.poll
      this.detailList[0].value = data.postsFeed
      this.detailList[1].value = data.postsLikes
      this.detailList[2].value = data.postsComments
      this.detailList2[0].value = data.survey
      this.detailList2[1].value = data.certs
    },
    async updateReaction () {
      const data = await reactEmojis(this.filterDates)
      this.totalReacoesList = [
        { reacao: '👍', total: data.likes },
        { reacao: '😍', total: data.love },
        { reacao: '🚀', total: data.rocket },
        { reacao: '😮', total: data.wow },
        { reacao: '👏', total: data.hands },
        { reacao: '😆', total: data.incredible }
      ]
    },
    async updateRanking () {
      const data = await ranking(this.filterDates)
      this.rankingGamificacaoList = data.map(item => ({
        pic_url: item.avatar ?? defaultAvatar,
        name: item.name,
        email: item.email,
        points: item.totalPoints
      }))
    },
    async updatePageviews () {
      const data = await pageviews(this.filterDates)
      this.acessosUnicos = data.uniqueViews
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.updateValues()
      },
      deep: true
    }
  },
  created () {
    this.updateValues()
  }
}
</script>

<style scoped>
.same-rent {
  flex: 1 1 calc(100% / 3)
}
</style>
