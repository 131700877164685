import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function pageviews ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/pageviews', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    uniqueViews: data.uniqueViews ?? 0
  }
}
